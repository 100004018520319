import { useState, useEffect } from 'react';
import { useTimer } from 'use-timer';
import st from './butterfly.module.scss';

const ButterflyNew = () => {
  const [state, setState] = useState<string>('open');  
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });

  const updateMousePosition = (ev: any) => {
    setMousePosition({ x: ev.clientX, y: ev.clientY });
  };

  useEffect(() => {
    document.addEventListener('mousemove', updateMousePosition, false);   

    return () => {
      document.removeEventListener('mousemove', updateMousePosition, false);     
    };
  }, []);

  const changeState = () => {   
    switch (state) {
      case 'open':
        setState('openClose');          
        break;
      case 'openClose':
        setState('close');     
        break;
      case 'close':
        setState('closeOpen');          
        break;
      case 'closeOpen':
        setState('open');       
        break;
      default:
        break;
    }
  };

  const { time, start, pause, reset, status } = useTimer({
    interval: 200,
    onTimeUpdate: changeState,
  });
 
  useEffect(() => {
    start();
  }, []);

  return (
    <>
      <div
        className={`${st.container} ${st[state]}`}
        style={{
          left: `${mousePosition.x}px`,
          top: `${mousePosition.y}px`,
        }}
      ></div>
    </>
  );
};
export default ButterflyNew;

