import { useState, useEffect } from "react";

const useActiveTab = (
  onBlurCallback = (f) => f,
  onFocusCallback = (f) => f
) => {
  const [hidden, setHidden] = useState(null);
  const [visibilityState, setVisibilityState] = useState(null);
  const [visibilityChange, setVisibilityChange] = useState(null);

  useEffect(() => {
    if (typeof document.hidden !== "undefined") {
      setHidden("hidden");
      setVisibilityState("visibilityState");
      setVisibilityChange("visibilitychange");
    } else if (typeof document.mozHidden !== "undefined") {
      setHidden("mozHidden");
      setVisibilityState("mozVisibilityState");
      setVisibilityChange("mozvisibilitychange");
    } else if (typeof document.msHidden !== "undefined") {
      setHidden("msHidden");
      setVisibilityState("msVisibilityState");
      setVisibilityChange("msvisibilitychange");
    } else if (typeof document.webkitHidden !== "undefined") {
      setHidden("webkitHidden");
      setVisibilityState("webkitVisibilityState");
      setVisibilityChange("webkitvisibilitychange");
    }
  }, []);

  useEffect(() => {
    const listener = () => {
      if (!visibilityState) return;
      switch (document[visibilityState]) {
        case "visible":
          if (onFocusCallback) onFocusCallback();
          break;
        case "hidden":
          if (onBlurCallback) onBlurCallback();
          break;
      }
    };
    if (hidden && visibilityState && visibilityChange) {
      document.addEventListener(visibilityChange, listener, false);
    } else {
      // console.log("not supported");
      // not supported
    }

    return () => {
      document.removeEventListener(visibilityChange, listener, false);
    };
  }, [hidden, visibilityState, visibilityChange]);

  return {};
};

export default useActiveTab;
