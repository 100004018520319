import moment from 'moment';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import st from './videoControls.module.scss';

const Controls = (props: any) => {
  const { viewer, videoElement, setPlay } = props;
  const [progress, setProgress] = useState(0);
  const progressBar = document.getElementById('progress-bar');
  const circle = document.getElementById('circle');
  const [debState, setDebState] = useState(true);

  const initializeVideo = () => {
    const videoDuration = String(Math.round(viewer.videoEl.duration));
    if (circle && progressBar) {
      circle.setAttribute('max', videoDuration);
      progressBar.setAttribute('max', videoDuration);
      circle.addEventListener('mousemove', (e) => {
        updateSeekData(e);
      });
      circle.addEventListener('input', skipAhead);
    }
  };

  const debounce = () => {
    if (!debState) {         
      return;
    } else if (props.isControls) {      
      props.handlePlayer();
      setDebState(false);
      setTimeout(() => {
        setDebState(true);
      }, 500);
    }
  };

  const updateSeekData = (event: any) => {
    if (circle) {
      const skipTo = String(
        Math.round(
          (event.offsetX / event.target.clientWidth) *
            parseInt(event.target.getAttribute('max'), 10)
        )
      );
      circle.setAttribute('data-seek', skipTo);
    }
  };

  const skipAhead = (event: any) => {
    if (circle && progressBar) {
      const skipTo = event.target.dataset.seek
        ? event.target.dataset.seek
        : event.target.value;
      viewer.videoEl.currentTime = skipTo;
      setProgress(skipTo);
    }
  };

  useEffect(() => {
    if (videoElement && viewer?.videoEl) {
      videoElement.addEventListener('timeupdate', () =>       
        setProgress(Math.floor(viewer.videoEl.currentTime))
      );

      videoElement.addEventListener('loadedmetadata', () => {
        initializeVideo();
      });
    }
    return () => {
      if (videoElement && viewer?.videoEl) {
        videoElement.removeEventListener('timeupdate', () =>       
          setProgress(Math.floor(viewer.videoEl.currentTime))
        );
        videoElement.removeEventListener('loadedmetadata', () => {
          initializeVideo();
        });
      }
    };
  }, [viewer]);
  
  const handleLeft = (): void => {
    if (viewer?.videoEl) {
      let newPos = Math.floor(viewer.videoEl.currentTime) - 5;
      viewer.videoEl.currentTime = newPos;
      setProgress(newPos);
    }
  };

  const handleRight = (): void => {
    if (viewer?.videoEl) {
      let newPos = Math.floor(viewer.videoEl.currentTime) + 5;
      viewer.videoEl.currentTime = newPos;
      setProgress(newPos);
    }
  };

  const handlePlay = (): void => {
    setPlay(true);
  };

  const handlePause = (): void => {
    setPlay(false);
  };

  const getCurrent = (): any => {
    if (viewer?.videoEl?.currentTime) {
      let current = viewer.videoEl.currentTime;
      let str = moment.utc(current * 1000).format('mm:ss');
      return str;
    } else {
      return '--:--';
    }
  };

  const getDuration = (): any => {
    if (viewer?.videoEl?.duration) {
      let duration = Math.round(viewer.videoEl.duration);
      let str = moment.utc(duration * 1000).format('mm:ss');
      return str;
    } else {
      return '--:--';
    }
  };

  return (
    <div
      className={props.isControls ? st.container_open : st.container}
      onMouseMove={debounce}      
    >
      <div className={st.buttons}>
        <div className={st.scrubLeft} onClick={handleLeft}></div>
        <div className={st.scrubRight} onClick={handleRight}></div>
        {props.isPlay ? (
          <div className={st.play} onClick={handlePause}></div>
        ) : (
          <div className={st.pause} onClick={handlePlay}></div>
        )}
      </div>
      <div className={st.containerBar}>
        <progress
          className={st.bar}
          id='progress-bar'
          value={`${progress}`}         
        ></progress>
        <input
          className={st.circle}
          id='circle'
          value={`${progress}`}
          min='0'
          type='range'
          step='1'       
          onChange={() => {}}
        ></input>      
      </div>
      <div className={st.minutes}>
        {viewer?.videoEl?.currentTime ? getCurrent() : '--:--'} /{' '}
        {getDuration()}
      </div>
    </div>
  );
};

export default Controls;
