import THREE, {
  Scene,
  PerspectiveCamera,
  WebGLRenderer,
  MeshBasicMaterial,
  SphereGeometry,
  VideoTexture,
  Mesh,
  Vector3,
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import {
  isMobile,
  isIOS, 
} from 'react-device-detect';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { videoSources } from '../context/constants';

export class Viewer {
  constructor(containerHTMLElement, videoHtmlElement, playParams, segment) {
    this.containerHTMLElement = containerHTMLElement;
    this.segment = segment;
  
    if (isIOS) {
      this.video = videoHtmlElement;
      this.videoEl = videoHtmlElement;
    } else {
      this.video = videojs(videoHtmlElement, playParams);
      this.videoEl = this.video.el().querySelector('video');
    }

    this.isUserInteracting = false;
    this.distance = 50;
    this.init();
    this.animate();
  }

  setVideoSource = (segment) => {    
    if (isIOS) {
      this.video.src = videoSources[segment].mobile.src;
    } else {
      this.video.src(videoSources[segment].dash);

      this.video.on('error', () => {
        if (isMobile) {
          this.video.src(videoSources[segment].mobile);
        } else {
          this.video.src(videoSources[segment].desktop);
        }
      });
    }
  };

  init = () => {
    const { offsetWidth, offsetHeight } = this.containerHTMLElement;
    this.camera = new PerspectiveCamera(
      75,
      offsetWidth / offsetHeight,
      1,
      1100
    );

    this.scene = new Scene();
    const geometry = new SphereGeometry(500, 60, 40);  
    geometry.scale(-1, 1, 1);

    this.setVideoSource(this.segment);    
    this.video.pause();  

    const texture = new VideoTexture(this.videoEl);
    const material = new MeshBasicMaterial({ map: texture });
    const mesh = new Mesh(geometry, material);
    mesh.name = 'video';
    this.scene.add(mesh);  
    this.renderer = new WebGLRenderer();
    this.renderer.setSize(offsetWidth, offsetHeight);
    this.containerHTMLElement.appendChild(this.renderer.domElement);
    this.createControls();   
    window.addEventListener('resize', this.onWindowResize); 
  };

  onWindowResize = () => { 
    const { offsetWidth, offsetHeight } = this.containerHTMLElement;
    this.camera.aspect = offsetWidth / offsetHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(offsetWidth, offsetHeight);
  };

  createControls = () => {
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.minDistance = 50;   
    this.controls.enableZoom = false;
    this.controls.target = new Vector3(0, 0, 0);
    this.camera.position.set(0.1, 0.01, 0.01);
    this.controls.rotateSpeed *= -1;
    this.controls.enablePan = false;
    this.controls.update();
  };

  setPlay = () => {
    this.video.play();
  };

  setPause = () => {
    this.video.pause();
  };

  changeVideo = (newSegment) => {
    this.setVideoSource(newSegment);
    let plane = this.scene.children.find((mesh) => mesh.name === 'video');
    if (plane) {
      const texture = new VideoTexture(this.videoEl);
      plane.material = new MeshBasicMaterial({ map: texture });
      plane.material.needsUpdate = true;
    }
  };

  animate = () => {
    requestAnimationFrame(this.animate);
    this.renderer.render(this.scene, this.camera);
  };
}
