import React, { useState, useRef, useEffect, useContext } from 'react';
import { isIOS, isMobile, isMobileOnly } from 'react-device-detect';
import { useNavigate, useLocation } from 'react-router-dom';
import 'video.js/dist/video-js.css';
import { Viewer } from '../video/Viewer';
import { STORE } from './../context/index';
import st from './videoComponent.module.scss';
import VideoControls from './VideoControls';
import useActiveTab from '../hooks/useActiveTab';
let timer: any = null;

const VideoComponent = (props: any) => {
  const { data, setVisitedSegments, setShowVideoAlert } =
    useContext(STORE).user;
  const [isPlay, setPlay] = useState<boolean>(false);
  const [viewer, setViewer] = useState<any | null>(null);
  const [isAlert, setAlert] = useState<boolean>(true);
  const [isControls, setControls] = useState<boolean>(true);
  const [isMuted, setMuted] = useState<boolean>(false);
  const navigate = useNavigate();
  const videoContainer = useRef<any | null>(null);
  const videoElement = useRef<any | null>(null);
  const curPath = useLocation().pathname;

  useEffect(() => {
    document.title = `${document.title} | video`;
    navigate(`${curPath}/video`);
  }, []);

  useEffect(() => {
    if (viewer) {
      isPlay ? viewer.setPlay() : viewer.setPause();
    }
  }, [isPlay]);

  useEffect(() => {
    if (props.isCongrats === false) {
      handlePlayer();
    }
  }, [props.isCongrats]);

  let handleVideoEnd = () => {
    if (!props.isPlayAll) {
      setVisitedSegments({ segment: data.currentSegment, finished: true });
      props.setVideo(false);
      props.setCongrats(true);
    } else {
      setVisitedSegments({ segment: data.currentSegment, finished: true });
      props.setCongrats(true);
    }
  };

  useEffect(() => {
    if (videoElement.current) {
      videoElement.current.addEventListener('ended', () => {        
        if (!props.isPlayAll) {
          setVisitedSegments({ segment: data.currentSegment, finished: true });
          props.setVideo(false);
          props.setCongrats(true);
        } else {
          setVisitedSegments({ segment: data.currentSegment, finished: true });
          props.setCongrats(true);
        }
      });
    }

    if (!data.showVideoAlert) {
      setPlay(true);
      handlePlayer();
    }

    return () => {
      if (videoElement.current) {
        videoElement.current.removeEventListener('ended', handleVideoEnd);
      }
    };
  }, []);

  useEffect(() => {
    if (viewer && data?.currentSegment && props.isPlayAll) {
      viewer.changeVideo(data?.currentSegment);
      viewer.setPlay();
      document.title = `${document.title} | video`;
      navigate(`${curPath}/video`);
    }
  }, [data?.currentSegment]);

  useEffect(() => {
    if (viewer?.videoEl?.currentTime && viewer?.videoEl?.currentTime) {
      if (
        viewer.videoEl.currentTime / viewer.videoEl.duration > 0.5 &&
        data.segments[data.currentSegment] !== true
      ) {
        setVisitedSegments({ segment: data.currentSegment, finished: true });
      }
    }
  }, [viewer?.videoEl?.currentTime]);

  useEffect(() => {
    if (videoContainer && videoElement && viewer === null) {
      const viewer = new Viewer(
        videoContainer.current,
        videoElement.current,
        {
          ...playParams,
        },
        data.currentSegment
      );
      setViewer(viewer);
    }
  }, [videoContainer]);

  useActiveTab(viewer?.setPause, isPlay ? viewer?.setPlay : () => {});

  const handleGotIt = () => {
    setShowVideoAlert(false);
    setAlert(false);
    setPlay(true);
    handlePlayer();
  };

  const handleVoice = () => {
    if (viewer && viewer.videoEl) {
      isMuted ? (viewer.videoEl.muted = false) : (viewer.videoEl.muted = true);
      setMuted((prev) => !prev);
    }
  };

  const handlePlayer = () => {
    clearTimeout(timer);
    setControls(true);
    let timeId = setTimeout(() => {
      setControls(false);
    }, 3000);
    timer = timeId;
  };

  return (
    <div>
      <div className={st.container} ref={videoContainer}></div>
      {isAlert && data.showVideoAlert ? (
        <div
          className={st.alertContainer}
          style={{ top: isMobileOnly ? '15vh' : '' }}
        >
          <div
            className={st.videoAlert}
            style={{ padding: isMobile ? '1.5rem 4.5rem' : '' }}
          >
            <div className={isMobile ? st.mobileIcon : st.mouseIcon}></div>
            <div
              className={st.alertText}
              style={{
                color: isMobile ? '#32348e' : '',
                fontWeight: isMobile ? '900' : '',
                paddingTop: isMobile ? '0.7rem' : '',
              }}
            >
              {isMobile
                ? 'Tap and Drag to Explore the Video.'
                : 'Click and drag to explore.'}
            </div>

            <div
              className={st.gotButton}
              onClick={handleGotIt}
              style={{
                marginTop: isMobile ? '0.7rem' : '',
              }}
            >
              Got it
            </div>
          </div>
        </div>
      ) : null}
      <video
        className={isIOS ? '' : 'video-js vjs-default-skin'}
        crossOrigin='anonymous'
        playsInline
        style={{ display: 'none' }}
        ref={videoElement}
      ></video>
      <VideoControls
        viewer={viewer}
        videoElement={videoElement.current}
        isPlay={isPlay}
        setPlay={setPlay}
        isControls={isControls}
        handlePlayer={handlePlayer}
      ></VideoControls>
      <div className={st.controlsButton} onMouseOver={handlePlayer}></div>
      <div
        className={isMuted ? st.voiceBut : st.muteBut}
        onClick={handleVoice}
      ></div>
    </div>
  );
};

export default VideoComponent;

const playParams = {
  controls: true,
  preload: 'metadata',
  crossOrigin: 'anonymous',
  playsinline: 'playsinline',
  sources: [],
  width: '100%',
  height: '100%',
  fullscreenControl: true,
  aspectRatio: '16:8',
  html5: {
    overrideNative: true,
    vhs: {
      overrideNative: true,
    },
    nativeCaptions: false,
    nativeAudioTracks: false,
    nativeVideoTracks: false,
  },
};
