import React, { createContext, FC } from "react";
import useUserStore from "./useUserStore";


export const STORE = createContext<any>({});

const StoreProvider: FC = ({ children }) => {
  const user = useUserStore();

  return <STORE.Provider value={{ user }}>{children}</STORE.Provider>;
};

export default StoreProvider;
