import React, { useState, useEffect } from 'react';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import st from './sustainability.module.scss';
import Popup from './Popup';
import Bottle from './Bottle';

const Sustainability = (props: any) => {
  const [popup, setPopup] = useState<string | null>(null);
  const [isVisible, setVisible] = useState<boolean>(false);

  useEffect(() => {  
      setTimeout(() => {
        setVisible(true);
      }, 300);    
  }, []);

  const handleVideo = (): void => {
    props.setVideo('true');
  };

  const handleInfo = (str: string) => {
    setPopup(str);
  };

  return (
    <div className={isVisible ? st.visible : st.unvisible}>
      <Bottle></Bottle>     
      <div className={st.title}></div>
      <div
        className={st.hsVideo}
        onClick={handleVideo}
        style={{ top: isMobileOnly ? '8vh' : '', left: isMobile ? '26vw' : '' }}
      >
        <div></div>
      </div>
      <div className={st.hsOne} onMouseOver={() => handleInfo('one')}  style={{ top: popups.one.posY, left: popups.one.posX }}></div>
      <div className={st.hsTwo} onMouseOver={() => handleInfo('two')} style={{ top: popups.two.posY, left: popups.two.posX }}></div>
      <div
        className={st.hsThree}
        onMouseOver={() => handleInfo('three')}
        style={{ top: popups.three.posY, left: popups.three.posX }}
      ></div>
      <Popup
        data={popups.one}
        setPopup={setPopup}
        open={popup === 'one' ? 'open' : ''}
      ></Popup>
      <Popup
        data={popups.two}
        setPopup={setPopup}
        open={popup === 'two' ? 'open' : ''}
      ></Popup>
      <Popup
        data={popups.three}
        setPopup={setPopup}
        open={popup === 'three' ? 'open' : ''}
      ></Popup>
    </div>
  );
};

export default Sustainability;

const popups = {
  one: {
    text: 'Water is reused multiple times on a dairy farm to cool the milk, wash the barn and irrigate the crops.',
    posX: isMobileOnly ? '22vw': '28vw',
    posY: isMobileOnly ? '34vh' : '55.5vh',
    height: '9rem',
    imgSrc: '/assets/girl1.png',
  },
  two: {
    text: '"We’ve got to be good stewards of the land, good stewards to our cows to ensure we have a future out here."',
    spaninfo: '- Cody, Caprock Dairy',
    posX: isMobileOnly ? '50vw' : '57vw',
    posY: isMobileOnly ? '5vh' :  '21.5vh',
    height: '9rem',
    imgSrc: '/assets/cowboy1.png',
  },
  three: {
    text: 'The dairy industry has reduced its carbon footprint by 19% since 2007.',
    posX: isMobileOnly ? '52vw' : '60.5vw',
    posY: isMobileOnly ? '27vh' : '60vh',
    height: '7rem',
    imgSrc: '/assets/ground1.png',
  }, 
};
