import { useState, useEffect } from 'react';
import { useTimer } from 'use-timer';
import st from './butterfly.module.scss';

const Fly = (props: any) => {
  const [state, setState] = useState<string>('open');

  const changeState = () => {
    switch (state) {
      case 'open':
        setState('openClose');
        break;
      case 'openClose':
        setState('close');
        break;
      case 'close':
        setState('closeOpen');
        break;
      case 'closeOpen':
        setState('open');
        break;
      default:
        break;
    }
  };

  const { time, start, pause, reset, status } = useTimer({
    interval: 200,
    onTimeUpdate: changeState,
  });

  useEffect(() => {
    start();
  }, []);

  return (
    <>
      <div className={`${st.container} ${st[state]}`}></div>
    </>
  );
};
export default Fly;
