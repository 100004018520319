import React, { useReducer } from 'react';
import { ACTIONS } from './constants';

let initialState = {
  segments: {
    sustainability: false,
    feeding: false,
    housing: false,
    milking: false,
    transport: false,
  },
  currentSegment: 'main',
  showVideoAlert: true,
};

const oldData = localStorage.getItem('dairy_user');
const sessionData = sessionStorage.getItem('dairy_user');

initialState = oldData
  ? { ...initialState, ...JSON.parse(oldData) }
  : initialState;
initialState = sessionData
  ? { ...initialState, ...JSON.parse(sessionData) }
  : initialState;

const reducer = (state: any, action: any): any => {
  const { type, data } = action;

  switch (type) {
    case ACTIONS.SET_CURRENT_SEGMENT:
      const newState = {
        segments: state.segments,
        currentSegment: data,
      };
      localStorage.setItem('dairy_user', JSON.stringify(newState));
      return { ...newState, showVideoAlert: state.showVideoAlert };
    case ACTIONS.SET_VISITED_SEGMENTS:
      const visState = {
        currentSegment: state.currentSegment,
        segments: { ...state.segments, [data.segment]: data.finished },
      };
      localStorage.setItem('dairy_user', JSON.stringify(visState));
      return { ...visState, showVideoAlert: state.showVideoAlert };
    case ACTIONS.SET_SHOW_ALERT:
      sessionStorage.setItem(
        'dairy_user',
        JSON.stringify({ showVideoAlert: data })
      );
      return { ...state, showVideoAlert: data };
    default:
      return { ...state };
  }
};

const useUserStore = () => {
  const [data, dispatch] = useReducer(reducer, initialState);

  const setCurrentSegment = (segment: string): void => {
    dispatch({ type: ACTIONS.SET_CURRENT_SEGMENT, data: segment });
  };

  const setVisitedSegments = (visitedSegment: any): void => {
    dispatch({ type: ACTIONS.SET_VISITED_SEGMENTS, data: visitedSegment });
  };

  const setShowVideoAlert = (show: boolean) => {
    dispatch({ type: ACTIONS.SET_SHOW_ALERT, data: show });
  };
  const value = {
    data,
    setCurrentSegment,
    setVisitedSegments,
    setShowVideoAlert,
  };

  return value;
};

export default useUserStore;
