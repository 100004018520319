import React from 'react';
import { isMobile, useMobileOrientation } from 'react-device-detect';
import st from './main.module.scss';
import newMainImageMobile from '../assets/LogoLabel.png';
import mobilePortrait from '../assets/Rotate_icon.png';

const MobilePortrait = (props: any) => {
  const { isPortrait } = useMobileOrientation()
  return (
    <>
      {isMobile && isPortrait ? (
        <div
          className={st.warning}
          style={{
            zIndex: isMobile && isPortrait ? '1000' : '',
          }}
        >
          <div className={st.logoContainer}>
            <div className={st.mainChildContainer}>
              <img
                src={newMainImageMobile}
                className={st.mainNewImage}
                style={{ left: '-17rem' }}
              ></img>
              <h1 className={st.mainText}>Dairy Tour 360</h1>
            </div>
          </div>
          <div className={st.phoneWarn}>
            <img className={st.phoneWarnImg} src={mobilePortrait}></img>
            <div className={st.phoneWarnText}>
              Please rotate your device to enjoy this experience
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MobilePortrait;
