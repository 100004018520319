import React, { useEffect, useState } from 'react';
import st from './media.module.scss';

const MediaPopup = (props: any) => {
  const [isTextOpacity, setTextOpacity] = useState<Boolean>(false);

  const { popupMedia, mediaUrl, bgSrc } = props.mediaResource;

  useEffect(() => {
    setTimeout(() => {
      setTextOpacity(true);
    }, 0);
  }, []);

  const handleShare = () => {
    if (['Facebook', 'Twitter'].includes(popupMedia)) {
      window.open(mediaUrl, 'share', 'width=600,height=400');
    } else {
      window.open(mediaUrl);
    }
  };

  const handleClose = () => {
    props.setMediaResource('');
  };

  return (
    <div className={isTextOpacity ? st.openContainer : st.container}>
      <div className={st.close} onClick={handleClose}></div>
      <div
        className={st.icon}
        style={{ backgroundImage: `url(${bgSrc})` }}
      ></div>
      {popupMedia === 'Instagram' ? (
        <>
          <div className={st.title}>Visit Our Instagram</div>
          <div className={st.text}>
            Follow us on Instagram for more cool content like this.
          </div>
          <div className={st.button} onClick={handleShare}>
            Visit
          </div>
        </>
      ) : (
        <>
          <div className={st.title}>Share to {popupMedia}</div>
          <div className={st.text}>
            Invite your friends to experience the dairy farm.
          </div>
          <div className={st.button} onClick={handleShare}>
            Share
          </div>
        </>
      )}
    </div>
  );
};

export default MediaPopup;
