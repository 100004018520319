import React, { useState, useEffect } from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import st from './transport.module.scss';
import Popup from './Popup';
import Bottle from './Bottle';

const Feeding = (props: any) => {
  const [popup, setPopup] = useState<string | null>(null);
  const [isVisible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 300);
  }, []);

  const handleVideo = (): void => {
    props.setVideo(true);
  };

  const handleInfo = (str: string) => {
    setPopup(str);
  };

  return (
    <div className={isVisible ? st.visible : st.unvisible}>
      <Bottle></Bottle>
      <div
        className={st.title}      
      ></div>
      <div
        className={st.hsVideo}
        onClick={handleVideo}
        style={{
          top: isMobileOnly ? '15vh' : '',
          left: isMobile ? '29vw' : '',
        }}
      >
        <div></div>
      </div>
      <div
        className={st.hsOne}
        onMouseOver={() => handleInfo('one')}
        style={{ top: popups.one.posY, left: popups.one.posX }}
      ></div>
      <div
        className={st.hsTwo}
        onMouseOver={() => handleInfo('two')}
        style={{ top: popups.two.posY, left: popups.two.posX }}
      ></div>
      <Popup
        data={popups.one}
        setPopup={setPopup}
        open={popup === 'one' ? 'open' : ''}
      ></Popup>
      <Popup
        data={popups.two}
        setPopup={setPopup}
        open={popup === 'two' ? 'open' : ''}
      ></Popup>
    </div>
  );
};

export default Feeding;

const popups = {
  one: {
    text: 'It only takes 2 days for milk to get from the farm to your table.',
    posX: isMobileOnly ? '47.5vw' : '55.5vw',
    posY: isMobileOnly ? '38vh' : '60vh',
    height: '7rem',
    imgSrc: '/assets/children1.png',
  },
  two: {
    text: 'Milk provides more than 10% of your daily value of 13 essential nutrients.',
    posX: isMobileOnly ? '50vw' : '59vw',
    posY: isMobileOnly ? '10vh' : '30vh',
    height: '8.5rem',
    imgSrc: '/assets/child1.png',
  },
};
