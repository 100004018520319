import React from 'react';
import StoreProvider from './context/index';
import MainPage from './components/Main';
import Preloader from './components/Preloader';
import MobilePortrait from './components/MobilePortrait';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <StoreProvider>
      <BrowserRouter>     
        <MobilePortrait />
        <MainPage />
        <Preloader />
      </BrowserRouter>
    </StoreProvider>
  );
}

export default App;
