import React, { useContext, useEffect, useState } from 'react';
import st from './bottle.module.scss';
import { STORE } from './../context';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';

const Bottle = () => {
  const { user } = useContext(STORE);
  const [milkPos, setMilkPos] = useState(0);
  const [isBubble, setBubble] = useState(false);
  const [imgPos, setImgPos] = useState<number>(0);

  useEffect(() => {
    if (user?.data?.segments) {
      let number = Object.values(user?.data?.segments).filter(
        (item) => item === true
      ).length;
      setMilkPos(number);
    }
  }, [user?.data?.segments]);

  const setPosition = (pos: number) => {
    setTimeout(() => setImgPos(pos), pos * 1000);
  };

  useEffect(() => {
    if (milkPos) {
      let pos = 1;
      while (pos <= milkPos) {
        setPosition(pos);
        pos++;
      }
    }
  }, [milkPos]);

  const handleBubleSpeach = () => {
    setBubble(true);
    setTimeout(() => {
      setBubble(false);
    }, 3000);
  };

  return (
    <>
     {/* <div
      // className={isMobile ? st.mobContainer : ''}
      // style={{ top: isMobileOnly ? '51vh' : isTablet ? '74vh' : '' }}
      // style={{ top: isMobile ? '50%' : '' }}
     > */}
      <div className={isBubble ? st.bubbleOpen : st.bubbleCont}>
        Watch all the videos to fill the milk bottle.
      </div>
      <div className={st.container} onMouseOver={handleBubleSpeach}>
        <div
          className={st.img0}
          style={{ opacity: imgPos === 0 ? 1 : 0 }}
        ></div>
        <div
          className={st.img1}
          style={{ opacity: imgPos === 1 ? 1 : 0 }}
        ></div>
        <div
          className={st.img2}
          style={{ opacity: imgPos === 2 ? 1 : 0 }}
        ></div>
        <div
          className={st.img3}
          style={{ opacity: imgPos === 3 ? 1 : 0 }}
        ></div>
        <div
          className={st.img4}
          style={{ opacity: imgPos === 4 ? 1 : 0 }}
        ></div>
        <div
          className={st.img5}
          style={{ opacity: imgPos === 5 ? 1 : 0 }}
        ></div>
      </div>
    {/* </div> */}
    </>
  );
};

export default Bottle;
