import React from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import st from './popup.module.scss';

const Popup = (props: any) => {
  const { text, posX, posY, height, width, imgSrc, spaninfo } = props.data;

  const handleClose = (e: any) => { 
    e.preventDefault();  
    e.stopPropagation();
    if (props.open === 'open') {      
      props.setPopup(false);
    }
  }

  return (
    <div
      className={
        props.open === 'open'
          ? isMobileOnly
            ? st.container_open_mobile
            : st.container_open
          : st.container
      }
      style={{ left: `${posX}`, top: `${posY}` }}
      onMouseLeave={() => props.setPopup(false)}
    >
      {isMobile ? (
        <div
          className={st.close}
          onTouchEnd={(e) => {
            handleClose(e);
          }}
        ></div>
      ) : null}
      <div
        className={st.infoIcon}
        onTouchEnd={
          isMobile && props.open === 'open'
            ?
          (e) => {
            handleClose(e);
          }
          : () => {}
        }
      ></div>
      <div
        className={
          props.open === 'open'
            ? isMobileOnly
              ? st.textContainer_open_mobile
              : st.textContainer_open
            : st.textContainer
        }
      >
        <div
          className={st.cowIcon}
          style={{
            backgroundImage: `url(${imgSrc})`,
            width: isMobileOnly ? '9rem' : '',
            height: isMobileOnly ? '9rem' : '',
            right: isMobileOnly ? '-5rem' : '',
          }}
        ></div>
        <div
          className={st.shape}
          style={{ height: height ? height : '' }}
        ></div>
        {text}
        <b style={{ fontWeight: '900' }}>{spaninfo ? spaninfo : ''}</b>
      </div>
    </div>
  );
};

export default Popup;
