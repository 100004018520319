import React, { useContext, useEffect, useState } from 'react';
import Bottle from './Bottle';
import st from './firstPage.module.scss';
import { STORE } from './../context';

const FirstPage = (props: any) => {
  const { data } = useContext(STORE).user;
  const [isVisible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (props.scene === 'main') {
      setTimeout(() => {
        setVisible(true);
      }, 300);
    }
  }, [props.scene]);

  return (
    <div className={isVisible ? st.visible : st.unvisible}>
      <Bottle></Bottle>
      <div className={st.title}></div>
      <div
        className={
          data.segments.sustainability ? st.hsSustain_green : st.hsSustain
        }
        onClick={() => props.zoomScene('sustainability')}
      >
        <div></div>
      </div>
      <div
        className={data.segments.feeding ? st.hsFeeding_green : st.hsFeeding}
        onClick={() => props.zoomScene('feeding')}
      >
        {' '}
        <div></div>
      </div>
      <div
        className={data.segments.housing ? st.hsHousing_green : st.hsHousing}
        onClick={() => props.zoomScene('housing')}
      >
        {' '}
        <div></div>
      </div>
      <div
        className={data.segments.milking ? st.hsMilking_green : st.hsMilking}
        onClick={() => props.zoomScene('milking')}
      >
        {' '}
        <div></div>
      </div>
      <div
        className={
          data.segments.transport ? st.hsTransport_green : st.hsTransport
        }
        onClick={() => props.zoomScene('transport')}
      >
        {' '}
        <div></div>
      </div>
    </div>
  );
};

export default FirstPage;
