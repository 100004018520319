import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import st from './congrats.module.scss';
import { STORE } from './../context';

const Congrats = (props: any) => {
  const { data, setCurrentSegment } = useContext(STORE).user;
  const [nextScene, setNextScene] = useState<string>('sustainability');
  const [hover, setHover] = useState<Boolean>(false);
  const [hovBack, setHovBack] = useState({ hov: '', src: '' });
  const navigate = useNavigate();

  useEffect(() => {
    switch (data.currentSegment) {
      case 'sustainability':
        setHovBack({
          hov: nextSc.feeding.hov,
          src: nextSc.feeding.src,
        });
        setNextScene('feeding');
        break;
      case 'feeding':
        setHovBack({
          hov: nextSc.housing.hov,
          src: nextSc.housing.src,
        });
        setNextScene('housing');       
        break;
      case 'housing':
        setHovBack({
          hov: nextSc.milking.hov,
          src: nextSc.milking.src,
        });
        setNextScene('milking');       
        break;
      case 'milking':
        setHovBack({
          hov: nextSc.transport.hov,
          src: nextSc.transport.src,
        });
        setNextScene('transport');       
        break;
      case 'transport':
        setHovBack({
          hov: nextSc.sustainability.hov,
          src: nextSc.sustainability.src,
        });
        setNextScene('sustainability');       
        break;
      default:
        break;
    }
  }, []);

  const handleNext = () => {
    props.setCongrats(false);
    switch (data.currentSegment) {
      case 'sustainability':
        document.title = 'Dairy Tour 360 | Feeding';
        navigate('/feeding');
        props.setScene('feeding');
        setCurrentSegment('feeding');
        props.zoomImage('feeding');
        break;

      case 'feeding':
        document.title = 'Dairy Tour 360 | Housing';
        navigate('/housing');
        props.setScene('housing');
        setCurrentSegment('housing');
        props.zoomImage('housing');
        break;

      case 'housing':
        document.title = 'Dairy Tour 360 | Milking';
        navigate('/milking');
        props.setScene('milking');
        setCurrentSegment('milking');
        props.zoomImage('milking');
        break;

      case 'milking':
        document.title = 'Dairy Tour 360 | Transportation & Processing';
        navigate('/transportation&processing');
        props.setScene('transport');
        setCurrentSegment('transport');
        props.zoomImage('transport');
        break;

      case 'transport':
        document.title = 'Dairy Tour 360 | Sustainability';
        navigate('/sustainability');
        props.setScene('sustainability');
        setCurrentSegment('sustainability');
        props.zoomImage('sustainability');
        break;
      default:
        break;
    }
  };

  const handleHome = () => {
    props.setCongrats(false);
    props.zoomOut();
  };

  const handleClose = () => {
    props.setCongrats(false);
  };

  return (
    <>      
      <div className={st.container}>
        <div className={st.congratsContainer}>
          <div className={st.close} onClick={handleClose}></div>
          <div className={st.congrats}>
            <div className={st.congratsTitle}>Nice!</div>
            <div className={st.congratsText}>
              Your milk bottle is filling up.
            </div>
            <div
              className={st.nextButton}
              onClick={handleNext}
              onMouseOver={() => {
                setHover(true);
              }}
              onMouseLeave={() => {
                setHover(false);
              }}
              style={{
                backgroundImage: hover
                  ? `url(${hovBack.hov})`
                  : `url(${hovBack.src})`,
              }}
            >
              Next video: {nextSc[nextScene].txt}
            </div>
            <div className={st.homeButton} onClick={handleHome}>
              Back to explore
            </div>
          </div>
        </div>
      </div>    
    </>
  );
};
export default Congrats;

interface keyPair {
  [index: string]: any;
}

const nextSc: keyPair = {
  feeding: {
    txt: 'Feeding',
    hov: '/assets/NextFeedingHover.png',
    src: '/assets/NextFeedingStatic.png',
  },
  housing: {
    txt: 'Housing',
    hov: '/assets/NextHousingHover.png',
    src: '/assets/NextHousingStatic.png',
  },
  milking: {
    txt: 'Milking',
    hov: '/assets/NextMilkingHover.png',
    src: '/assets/NextMilkingStatic.png',
  },
  transport: {
    txt: 'Transportation & Processing',
    hov: '/assets/NextTransportHover.png',
    src: '/assets/NextTransportStatic.png',
  },
  sustainability: {
    txt: 'Sustainability',
    hov: '/assets/NextSustainabilityHover.png',
    src: '/assets/NextSustainabilityStatic.png',
  },
};
