import React, { useState, useEffect } from 'react';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import Bottle from './Bottle';
import st from './feeding.module.scss';
import Popup from './Popup';

const Feeding = (props: any) => {
  const [popup, setPopup] = useState<string | null>(null);
  const [isVisible, setVisible] = useState<boolean>(false);

  useEffect(() => {  
      setTimeout(() => {
        setVisible(true);
      }, 300);    
  }, []);
  
  const handleVideo = (): void => {
    props.setVideo(true);
  };

  const handleInfo = (str: string) => {
    setPopup(str);
  };

  return (
    <div className={isVisible ? st.visible : st.unvisible}>
      <Bottle></Bottle>     
      <div className={st.title}></div>
      <div className={st.hsVideo} onClick={handleVideo} style={{left: isMobileOnly ? "27vw" : "", top: isMobileOnly ? "24vh" : ""}}>
        <div></div>
      </div>
      <div
        className={st.hsOne}
        onMouseOver={() => handleInfo('one')}
        style={{ top: popups.one.posY, left: popups.one.posX }}
      ></div>
      <div className={st.hsTwo} onMouseOver={() => handleInfo('two')} style={{ top: popups.two.posY, left: popups.two.posX }}></div>
      <Popup
        data={popups.one}
        setPopup={setPopup}
        open={popup === 'one' ? 'open' : ''}
      ></Popup>
      <Popup
        data={popups.two}
        setPopup={setPopup}
        open={popup === 'two' ? 'open' : ''}
      ></Popup>
    </div>
  );
};

export default Feeding;

const popups = {
  one: {
    text: 'Cows have special stomachs that allow them to digest plants that humans can’t.',
    posX:  isMobileOnly ? '43vw': '29vw',
    posY: isMobileOnly ? '37vh' : '64vh',
    height: '8rem',
    imgSrc: '/assets/orangeCow1.png',
  },
  two: {
    text: 'Cows diets are carefully designed by an animal nutritionist to fit their needs at each stage of life.',
    posX:  isMobileOnly ? '57vw' : '47vw',
    posY: isMobileOnly ? '14vh': '24vh',
    height: '9rem',
    imgSrc: '/assets/feeding1.png',
  }, 
};
