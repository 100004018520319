import React from 'react';
import Box from '@material-ui/core/Box';
import {
  makeStyles,
  createStyles,
  withStyles,
  Theme,
} from '@material-ui/core/styles';
import LinearProgress, {
  LinearProgressProps,
} from '@material-ui/core/LinearProgress';
import { ReactComponent as LoaderIcon } from '../assets/loader.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    _root: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    },
    root: {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      opacity: 1,
      transition: 'all .5s',      
    },
    wrapper: {
      position: 'relative',
      display: 'inline-block',
      padding: 0,
      marginTop: 0,    
    },
    root_hidden: {
      opacity: '0!important',
    },   
    svg: {
   
      width: '3.25rem',
      transform: 'rotate(-90deg)',
      height: '3.25rem',
    },
    progress: {
      position: 'absolute',     
      width: '100%',
      height: '100%',
      left: '50%',
      fontSize: '0.8rem',
      top: '50%',
      display: 'flex',
      color: 'black',
      justifyContent: 'center',
      alignItems: 'center',
      transform: 'translate(-48%, -53%)',
    },
  })
);

const LabelProgress: React.FC<
  LinearProgressProps & { value: number; show: boolean }
> = (props) => {
  const classes = useStyles();

  const min = 400;
  const max = 560;

  return (   
      <Box
        style={{ opacity: props.show ? 1 : 0 }}
        id='_progress'
        className={classes.root}
      >
        <Box className={classes.wrapper}>
          <LoaderIcon className={classes.svg} />
          <Box className={classes.progress}>{props.value}%</Box>
        </Box>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          #prgrs {
            stroke-dashoffset: 400;
            transition: all .1s;
            stroke-dasharray: ${min + (max - min) * (props.value / 100)};
          }
        `,
          }}
        ></style>
      </Box>   
  );
};

interface IProgressProps {
  value: number;
  show: boolean;
}

export const Progress: React.FC<IProgressProps> = ({ value, show }) => {
  return <LabelProgress value={value} show={show} />;
};
