import React, { useState, useEffect } from 'react';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import Bottle from './Bottle';
import st from './housing.module.scss';
import Popup from './Popup';

const Housing = (props: any) => {
  const [popup, setPopup] = useState<string | null>(null);
  const [isVisible, setVisible] = useState<boolean>(false);

  useEffect(() => {  
      setTimeout(() => {
        setVisible(true);
      }, 300);    
  }, []);

  const handleVideo = (): void => {
    props.setVideo(true);
  };

  const handleInfo = (str: string) => {
    setPopup(str);
  };

  return (
    <div className={isVisible ? st.visible : st.unvisible}>
      <Bottle></Bottle>      
      <div className={st.title}></div>
      <div
        className={st.hsVideo}
        style={{ top: isMobileOnly ? '13vh' : '' }}
        onClick={handleVideo}
      >
        <div></div>
      </div>
      <div className={st.hsOne} onMouseOver={() => handleInfo('one')} style={{ top: popups.one.posY}}></div>
      <div
        className={st.hsTwo}
        onMouseOver={() => handleInfo('two')}
        onClick={isMobile ? () => handleInfo('two') : () => {}}
        style={{ left: popups.two.posX,  top: popups.two.posY}}
      ></div>
      <Popup
        data={popups.one}
        setPopup={setPopup}
        open={popup === 'one' ? 'open' : ''}
      ></Popup>
      <Popup
        data={popups.two}
        setPopup={setPopup}
        open={popup === 'two' ? 'open' : ''}
      ></Popup>
    </div>
  );
};

export default Housing;

const popups = {
  one: {
    text: 'Many dairies have automatic back scratchers for the cows.',
    posX: '20vw',
    posY: isMobileOnly ? '37vh' : '51vh',
    height: '7rem',
    imgSrc: '/assets/cowIcon1.png',
  },
  two: {
    text: 'The more calm and comfortable cows are, the more milk they make.',
    posX: isMobileOnly ? '48vw' : '64vw',
    posY: isMobileOnly ? '28vh': '53vh',
    height: '7rem',
    imgSrc: '/assets/blackWhiteCow1.png',
  },
};
