import React, { useState, useEffect } from 'react';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import Bottle from './Bottle';
import st from './milking.module.scss';
import Popup from './Popup';

const Milking = (props: any) => {
  const [popup, setPopup] = useState<string | null>(null);
  const [isVisible, setVisible] = useState<boolean>(false);

  useEffect(() => {  
      setTimeout(() => {
        setVisible(true);
      }, 300);    
  }, []);

  const handleVideo = (): void => {
    props.setVideo(true);
  };

  const handleInfo = (str: string) => {
    setPopup(str);
  };

  return (
    <div className={isVisible ? st.visible : st.unvisible}>
      <Bottle></Bottle>    
      <div className={st.title}></div>
      <div
        className={st.hsVideo}
        onClick={handleVideo}
        style={{ top: isMobileOnly ? '13vh' : '', left: isMobile ? '27vw' : '' }}
      >
        <div></div>
      </div>
      <div
        className={st.hsOne}
        onMouseOver={() => handleInfo('one')}
        style={{ top: popups.one.posY, left: popups.one.posX }}
      ></div>
      <div
        className={st.hsTwo}
        onMouseOver={() => handleInfo('two')}
        style={{ top: popups.two.posY }}
      ></div>
      <div
        className={st.hsThree}
        onMouseOver={() => handleInfo('three')}
        style={{ top: popups.three.posY }}
      ></div>
      <Popup
        data={popups.one}
        setPopup={setPopup}
        open={popup === 'one' ? 'open' : ''}
      ></Popup>
      <Popup
        data={popups.two}
        setPopup={setPopup}
        open={popup === 'two' ? 'open' : ''}
      ></Popup>
      <Popup
        data={popups.three}
        setPopup={setPopup}
        open={popup === 'three' ? 'open' : ''}
      ></Popup>
    </div>
  );
};

export default Milking;

const popups = {
  one: {
    text: 'Cows need to be milked 2-3 times a day.',
    posX: isMobileOnly ? '54.5vw' :'60.5vw',
    posY: isMobileOnly ? '8vh' : '37vh',
    height: '7.5rem',
    width: '17.5rem',
    imgSrc: '/assets/milking1.png',
  },
  two: {
    text: 'Cows are milked with gentle machines.',
    posX: '55vw',
    posY: isMobileOnly ? '31vh' : '58vh',
    height: '5.5rem',
    width: '18.5rem',
    imgSrc: '/assets/cowLegs1.png',
  },
  three: {
    text: 'Milk is never touched by human hands.',
    posX: '28vw',
    posY: isMobileOnly ? '42vh' : '62vh',
    height: '5.5rem',
    width: '18.5rem',
    imgSrc: '/assets/manInRed1.png',
  },  
};
