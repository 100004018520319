export const ACTIONS = {
  SET_CURRENT_SEGMENT: 'SET_CURRENT_SEGMENT',
  SET_VISITED_SEGMENTS: 'SET_VISITED_SEGMENTS',
  SET_SHOW_ALERT: 'SET_SHOW_ALERT',
};

export const videoSources = {
  sustainability: {
    hls: {
      src: 'https://ep-dairymax-prod-ep-dairymax-mkio-sub.centralus.streaming.mediakind.com/a360eae9-594c-432d-b39b-95228517ff72/01_Sustainability_RC7.ism/manifest(format=m3u8-cmaf)',
      type: 'application/x-mpegURL',      
    },
    dash: {
      src: 'https://ep-dairymax-prod-ep-dairymax-mkio-sub.centralus.streaming.mediakind.com/a360eae9-594c-432d-b39b-95228517ff72/01_Sustainability_RC7.ism/manifest(format=mpd-time-cmaf)',
      type: 'application/dash+xml',
    },
    mobile: {
      src: '/assets/video/Video-01_Sustainability_RC7-Video-2K-5065.mp4',
      type: 'video/mp4',
    },
    desktop: {
      src: '/assets/video/Video-01_Sustainability_RC7-Video-4K-16170.mp4',
      type: 'video/mp4',
    },
  },
  feeding: {
    hls: {
      src: 'https://ep-dairymax-prod-ep-dairymax-mkio-sub.centralus.streaming.mediakind.com/5d921f97-38a2-418e-a9f4-89649b1c19c4/02_Feeding_RC6-005.ism/manifest(format=m3u8-cmaf)',
      type: 'application/x-mpegURL',
    },
    dash: {
      src: 'https://ep-dairymax-prod-ep-dairymax-mkio-sub.centralus.streaming.mediakind.com/5d921f97-38a2-418e-a9f4-89649b1c19c4/02_Feeding_RC6-005.ism/manifest(format=mpd-time-cmaf)',
      type: 'application/dash+xml',
    },
    mobile: {
      src: '/assets/video/Video-02_Feeding_RC6-Video-2K-5065.mp4',
      type: 'video/mp4',
    },
    desktop: {
      src: '/assets/video/Video-02_Feeding_RC6-Video-4K-16170.mp4',
      type: 'video/mp4',
    },
  },
  housing: {
    hls: {
      src: 'https://ep-dairymax-prod-ep-dairymax-mkio-sub.centralus.streaming.mediakind.com/8407e6d0-8df7-4d75-a995-d821cbb277d1/03_Housing_RC6.ism/manifest(format=m3u8-cmaf)',
      type: 'application/x-mpegURL',
    },
    dash: {
      src: 'https://ep-dairymax-prod-ep-dairymax-mkio-sub.centralus.streaming.mediakind.com/8407e6d0-8df7-4d75-a995-d821cbb277d1/03_Housing_RC6.ism/manifest(format=mpd-time-cmaf)',
      type: 'application/dash+xml',
    },
    mobile: {
      src: '/assets/video/Video-03_Housing_RC6-Video-2K-5065.mp4',
      type: 'video/mp4',
    },
    desktop: {
      src: '/assets/video/Video-03_Housing_RC6-Video-4K-16170.mp4',
      type: 'video/mp4',
    },
  },
  milking: {
    hls: {
      src: 'https://ep-dairymax-prod-ep-dairymax-mkio-sub.centralus.streaming.mediakind.com/a8a34329-f381-41bd-a402-1a00ed324ad1/04_Milking_RC6.ism/manifest(format=m3u8-cmaf)',
      type: 'application/x-mpegURL',
    },
    dash: {
      src: 'https://ep-dairymax-prod-ep-dairymax-mkio-sub.centralus.streaming.mediakind.com/a8a34329-f381-41bd-a402-1a00ed324ad1/04_Milking_RC6.ism/manifest(format=mpd-time-cmaf)',
      type: 'application/dash+xml',
    },
    mobile: {
      src: '/assets/video/Video-04_Milking_RC6-Video-2K-5065.mp4',
      type: 'video/mp4',
    },
    desktop: {
      src: '/assets/video/Video-04_Milking_RC6-Video-2K-5065.mp4',
      type: 'video/mp4',
    },
  },
  transport: {
    hls: {
      src: 'https://ep-dairymax-prod-ep-dairymax-mkio-sub.centralus.streaming.mediakind.com/104ab423-7dcc-4482-b7f2-c7af870ac511/05_Processing_RC5-002.ism/manifest(format=m3u8-cmaf)',
      type: 'application/x-mpegURL',
    },
    dash: {
      src: 'https://ep-dairymax-prod-ep-dairymax-mkio-sub.centralus.streaming.mediakind.com/104ab423-7dcc-4482-b7f2-c7af870ac511/05_Processing_RC5-002.ism/manifest(format=mpd-time-cmaf)',
      type: 'application/dash+xml',
    },
    mobile: {
      src: '/assets/video/Video-05_Processing_RC5-Video-2K-5065.mp4',
      type: 'video/mp4',
    },
    desktop: {
      src: '/assets/video/Video-05_Processing_RC5-Video-2K-5065.mp4',
      type: 'video/mp4',
    },
  },
};
