import React, { useState, useEffect } from 'react';
import { useMobileOrientation } from 'react-device-detect';
import { useTimer } from 'use-timer';
// import data from "../assets/butterfly.json";
import st from './butterfly.module.scss';
import useActiveTab from '../hooks/useActiveTab';

import Fly from './Fly';

const initialState = { x: 10, y: 70, ang: -10 };
let number = 0;

const Butterfly = (props: any) => {
  const { isPortrait } = useMobileOrientation();

  const setPosition = () => {
    if (number < positions.length - 1) {
      setButPos(positions[number]);
      number++;
    } else {
      setButPos(positions[number]);
      number = 0;
    }
  };

  useEffect(() => {
    if (isPortrait) {
      stopInterval();
    } else {
      startInterval();
    }
  }, [isPortrait]);

  useEffect(() => {
    props.isVideo ? pause() : start();
  }, [props.isVideo]);

  const { time, start, pause, reset, status } = useTimer({
    interval: 1500,
    onTimeUpdate: setPosition,
  });
  const [butPos, setButPos] = useState<any>(initialState);

  useEffect(() => {
    start();
  }, []);

  const stopInterval = (): void => {
    pause();
  };

  const startInterval = (): void => {
    start();
  };

  useActiveTab(stopInterval, startInterval);

  return (
    <>
      {props.isVideo ? null : (
        <div
          className={st.butterflyContainer}
          style={{
            visibility: props.isVideo ? 'hidden' : 'visible',
            left: `${butPos.x}%`,
            top: `${butPos.y}%`,
            transform: `rotate(${butPos.ang}deg)`,
          }}
        >
          <Fly></Fly>
        </div>
      )}
    </>
  );
};

export default Butterfly;

const positions = [
  { x: 8, y: 60, ang: -5 },
  { x: 8, y: 50, ang: 0 },
  { x: 10, y: 40, ang: 10 },
  { x: 15, y: 30, ang: 30 },
  { x: 25, y: 25, ang: 60 },
  { x: 35, y: 25, ang: 90 },
  { x: 45, y: 35, ang: 120 },
  { x: 50, y: 50, ang: 140 },
  { x: 55, y: 60, ang: 150 },
  { x: 60, y: 70, ang: 135 },
  { x: 70, y: 80, ang: 110 },
  { x: 80, y: 80, ang: 80 },
  { x: 85, y: 70, ang: 45 },
  { x: 90, y: 60, ang: 20 },
  { x: 92, y: 50, ang: 0 },
  { x: 90, y: 40, ang: -15 },
  { x: 80, y: 30, ang: -45 },
  { x: 75, y: 25, ang: -60 },
  { x: 65, y: 20, ang: -90 },
  { x: 60, y: 30, ang: -120 },
  { x: 55, y: 35, ang: -150 },
  { x: 50, y: 50, ang: -135 },
  { x: 45, y: 60, ang: -120 },
  { x: 35, y: 70, ang: -90 },
  { x: 25, y: 80, ang: -60 },
  { x: 15, y: 75, ang: -30 },
  { x: 10, y: 70, ang: -10 },
];

interface KeyPair {
  [index: string]: string;
}

const wings: KeyPair = {
  open: '/assets/butterfly/ButterflyStateOne.png',
  openClose: '/assets/butterfly/ButterflyStateTwo.png',
  close: '/assets/butterfly/ButterflyStateThree.png',
  closeOpen: '/assets/butterfly/ButterflyStateTwo.png',
};
